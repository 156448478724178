import { colorNeutralsWhite, headerBorderColor, headerZIndex } from 'styles/variables';
import { useLanguageSelector } from 'contexts/LanguageSelectorContext';
import LanguageSelector from 'components/forms/LanguageSelector';
import MerLogo from 'components/visuals/MerLogo';
import styled from 'styled-components';

const Container = styled.header`
    grid-area: header;
    background-color: ${colorNeutralsWhite};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: ${headerZIndex};
    border-bottom: 1px solid ${headerBorderColor};
`;

function Header(): JSX.Element {
    const { showLanguageSelector } = useLanguageSelector();
    return (
        <Container data-testid="header">
            <MerLogo size="64px" />
            {showLanguageSelector && <LanguageSelector />}
        </Container>
    );
}

export default Header;
