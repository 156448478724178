import { EPageErrorType } from 'types';
import { PATH } from 'constants/paths';
import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import RouteWrapper from 'components/wrappers/RouteWrapper';

const DeliveryAddress = lazy(() => import('pages/DeliveryAddressPage'));
const DeliveryAddressNotifications = lazy(() => import('pages/DeliveryAddressNotifications'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const SkanskaOrderChargeKeyForPrivateCar = lazy(() => import('pages/swedishForms/SkanskaOrderChargeKeyForPrivateCar'));
const OrderChargeKeyLeasingCar = lazy(() => import('pages/swedishForms/SkanskaOrderChargeKeyLeasingCar'));
const OrderChargeKeySkanskaCar = lazy(() => import('pages/swedishForms/SkanskaOrderChargeKeySkanskaCar'));
const SkanskaOrderChargeKeyUtilityVehicle = lazy(() => import('pages/swedishForms/SkanskaUtilityVehiclePage'));
const OrderWallbox = lazy(() => import('pages/swedishForms/SkanskaOrderWallbox'));

function AppRoutes(): JSX.Element {
    return (
        <Routes>
            {import.meta.env.VITE_APP_ENV !== 'live' && <Route path="/noauth">noauth</Route>}
            <Route element={<RouteWrapper />}>
                <Route path={PATH.WILDCARD} element={<ErrorPage type={EPageErrorType.BROKEN_URL} />} />
                <Route path={PATH.ERROR} element={<ErrorPage type={EPageErrorType.TEMPORARY} />} />
                <Route path={PATH.CRITICAL_ERROR} element={<ErrorPage type={EPageErrorType.CRITICAL} />} />
                <Route
                    path={PATH.TEMPORARY_ERROR}
                    element={<ErrorPage type={EPageErrorType.TEMPORARY} home refresh />}
                />
                <Route path={PATH.PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS} element={<DeliveryAddress />} />
                <Route
                    path={PATH.PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS_STATUS}
                    element={<DeliveryAddressNotifications />}
                />
                <Route
                    path={PATH.PUBLIC_SUBSCRIPTIONS_WILDCARD}
                    element={<ErrorPage type={EPageErrorType.BROKEN_URL} />}
                />
                <Route path={PATH.PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS} element={<DeliveryAddress />} />
                <Route
                    path={PATH.PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS_STATUS}
                    element={<DeliveryAddressNotifications />}
                />
            </Route>
            <Route element={<RouteWrapper showLanguageSelector={false} />}>
                <Route path={PATH.ORDER_CHARGE_KEY_PRIVATE_CAR} element={<SkanskaOrderChargeKeyForPrivateCar />} />
                <Route path={PATH.ORDER_CHARGE_KEY_LEASING_CAR} element={<OrderChargeKeyLeasingCar />} />
                <Route path={PATH.ORDER_CHARGE_KEY_SKANSKA_CAR} element={<OrderChargeKeySkanskaCar />} />
                <Route path={PATH.ORDER_CHARGE_KEY_UTILITY_VEHICLE} element={<SkanskaOrderChargeKeyUtilityVehicle />} />
                <Route path={PATH.ORDER_WALLBOX} element={<OrderWallbox />} />
            </Route>
        </Routes>
    );
}

export default AppRoutes;
