export enum PATH {
    PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS = '/subscriptions/:subscriptionId/delivery-address',
    PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS_STATUS = '/subscriptions/delivery-address/:status',
    PUBLIC_SUBSCRIPTIONS_WILDCARD = '/subscriptions/*',

    ERROR = '/error',
    TEMPORARY_ERROR = '/temporaryError',
    CRITICAL_ERROR = '/criticalError',

    WILDCARD = '/*',

    ORDER_CHARGE_KEY_LEASING_CAR = '/form/skanska/chargeKeyForLeasingCar',
    ORDER_CHARGE_KEY_SKANSKA_CAR = '/form/skanska/chargeKeyForSkanskaCar',
    ORDER_CHARGE_KEY_PRIVATE_CAR = '/form/skanska/chargeKeyForPrivateCar',
    ORDER_CHARGE_KEY_UTILITY_VEHICLE = '/form/skanska/chargeKeyForUtilityVehicle',
    ORDER_WALLBOX = '/form/skanska/orderWallbox',
}
